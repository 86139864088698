<template>
  <page title="平台介绍">
    <div class="p-4 leading-5">平台介绍内容</div>
  </page>
</template>

<script>
export default {
  data(){
    return{
      
    }
  },
  methods:{},
  created(){}
}
</script>

<style lang='stylus'>

</style>